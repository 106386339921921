@import './variables.scss';

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $ai-see-bleu-background;
}

code {
  font-family: monospace;
}

div[class*='react-toast-notifications'] {
  z-index: 2000;
}

.padded {
  padding: 2em 2.5em 2em;
}

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body),
.pushable {
  height: 100vh;

  > .pusher:after {
    height: 100%;
    position: fixed;
  }
}

.step-monitor--header {
  height: 30px;
  display: flex;
  justify-content: flex-end;
}

.pusher.create-form {
  height: calc(100vh - 42px) !important;

  .create-new-session-segment {
    width: 100%;
    max-width: 60vw;
    padding: 2em !important;
    display: flex;
    flex-direction: column;
    margin-left: 4em;
    margin-top: 0;

    h3 {
      align-self: center;
    }
  }
}

.ui.segment.create-new-session-segment {
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.dot {
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  margin: auto;
  margin-right: 0.6em;
  border-radius: 50%;
  display: inline-block;

  &.red {
    background-color: #ec2a42;
  }

  &.green {
    background-color: #5be350;
  }

  &.orange {
    background-color: #ff9712;
  }

  &.cyan {
    background-color: #35ecff;
  }

  &.black {
    background-color: #000000;
  }
}

.pusher.create-selection-viewer {
  padding-top: 42px;
  padding-right: 9em;

  &.moved {
    padding-right: 21em;
  }

  &.is-loaded {
    transition: padding-right 0.38s ease-in-out;
  }
}

.not-in-progress {
  margin-bottom: 2em !important;
}
