@import '../variables.scss';

.unavailable-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  &_section {
    height: 70%;
    width: 50%;
    margin-top: 42px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &_text {
      font-family: Metropolis;
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.48px;
      text-align: center;
      color: $color-blue-darkest;
    }

    &_subtext {
      font-family: Metropolis;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 50px;
      color: $color-blue-darkest;
    }

    &_image{
      margin-top: 20px;
    }
  }
}