@import '../variables.scss';

.login {
  height: 100vh;
  display: flex;
  justify-content: center;

  &-segment {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 20px;
    margin: auto;
    width: 40%;
    max-width: 550px;
    padding: 1.5rem;
  }

  &-logo {
    padding: 2rem;

    .ui.small.image {
      width: 116px;
    }
  }

  &-message {
    color: $color-blue-darkest;
    font-size: 14px;
    line-height: normal;
    padding: 1.5rem 4rem;

    &-title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 1.5rem;
      color: $color-primary-purple;
    }
  }

  &-mail-sent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-form {
    padding: 0.5rem 4rem;
  }

  &-forgot-pwd-link {
    transform: translateY(-0.9rem) !important;
  }
  .not-receive-mail-link {
    transform: translateY(1rem) !important;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
  }

  a {
    color: $color-blue-darkest;
    text-decoration: underline;
    font-size: 12px;
  }

  &-continue-button {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
  }

  .ui.form .field.field input {
    border: none !important;
    border-bottom: $color-primary-purple 1px solid !important;
    padding: 0.8rem 0;
  }

  .ui.form .field > label {
    display: block;
    margin: 0em 0em 0.2rem 0em;
    color: $color-blue-darkest;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
  }

  .ui.button.submit {
    display: flex;
    align-items: center;
    color: white;
    fill: white;
    stroke: white;
    font-weight: bold;
    font-size: 18px;
    background: $color-primary-purple;
    border-radius: 6px;
    padding: 0.6rem 1rem;
    box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.1);

    &:hover:not(&:disabled) {
      background: $color-hover-purple;
    }

    &:active:not(&:disabled) {
      transform: translateY(0.2rem);
    }

    svg {
      margin-left: 0.3rem;
      stroke: rgb(255, 255, 255);
    }
    &:disabled {
      background-color: #8c94b5 !important;
    }
  }
}
