//colors
$color-grey: #9c9898;
$color-grey-dark: #979797;
$color-white-dark: #f4f4f4;
$border-color: lighten(
  $color: black,
  $amount: 50,
);
$ai-see-bleu-background: #f9fcff;
$color-primary-purple: #8397ff;
$color-hover-purple: #6579de;
$color-primary-grey: #8c94b5;
$color-hover-grey: #757c97;
$color-blue-darkest: #020a3a;
